<template>
  <div id="commodity">
    <Dialog ref="dialog" :config="config" />
    <addDia />
    <nav>
      <div class="navLeft">
        <div class="checkBoxZu">
          <label class="che" for="allCheck"
            ><input
              id="allCheck"
              type="checkBox"
              :checked="checked"
              @click="
                checked = !checked;
                allChoice();
              "
            />
            {{ $fanyi("全选") }}</label
          >
          <button @click="delGoods()">{{ $fanyi("删除") }}</button>
        </div>
        <!-- 搜索框 -->
        <!-- <div class="inputBox">
          <div class="imgBox">
            <img src="../../../../assets/newImg/搜索小.png" alt="" />
          </div>
          <el-input placeholder="请输入内容" v-model="form.keyword"> </el-input>
          <el-button type="primary" @click="getData">搜索</el-button>
        </div> -->
      </div>
      <div class="navRight">
        <!-- <div class="exportBox">
          <img src="../../../../assets/user-img/commodity/export.png" alt="" />
          <span>导出excel</span>
        </div> -->
        <el-button type="primary" @click="addDiaStuts = !addDiaStuts">{{
          $fanyi("添加")
        }}</el-button>
        <el-button class="ProponerUnPedido" @click="jieSuan">{{
          $fanyi("商品库提出订单")
        }}</el-button>
      </div>
    </nav>

    <!-- {{ tableData[3] }} -->
    <el-table
      :data="tableData"
      :header-cell-style="{
        'text-align': 'center',
        background: '#F0F0F0',
        'font-size': '12px',
        'text-align': 'center',
        color: '#000000',
        'background-color': '#F0F0F0',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column type="index" :label="$fanyi('Nº')" width="79">
        <template slot-scope="scope">
          <div>
            {{ (Number(form.page) - 1) * 10 + Number(scope.$index) + 1 }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$fanyi('选择')" width="90">
        <template slot-scope="scope">
          <div>
            <input
              class="chiose"
              type="checkBox"
              :checked="scope.row.checked"
              @click="
                scope.row.checked = !scope.row.checked;
                oddChoice(scope.$index);
              "
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column property="name" :label="$fanyi('照片')" width="100">
        <template slot-scope="scope">
          <div>
            <el-popover placement="right" trigger="hover">
              <img
                :src="scope.row.img_url"
                alt=""
                style="width: 300px; height: 300px"
              />
              <el-image
                slot="reference"
                :src="scope.row.img_url"
                @click="$fun.toCommodityDetails(scope.row.goods_id)"
                style="width: 100px; height: 100px; cursor: pointer"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="name" :label="$fanyi('商品链接')" width="230">
        <template slot-scope="scope">
          <el-popover placement="bottom" width="400" trigger="hover">
            {{ scope.row.goods_title }}

            <div class="goodsUrl" slot="reference">
              <a
                :href="
                  '	https://www.rakumart.es/ProductDetails?goods_id=' +
                    scope.row.goods_id
                "
                target="_blank"
              >
                {{ scope.row.goods_title }}
              </a>
            </div>
          </el-popover>
        </template></el-table-column
      >
      <el-table-column :label="$fanyi('商品属性')" width="175">
        <template slot-scope="scope">
          <el-popover placement="bottom" width="420" trigger="click">
            <div class="chioceDetail">
              <div
                class="dia"
                v-for="(item, index) in scope.row.specification"
                :key="index"
              >
                <span class="chioceTitle">{{ item.key }}:</span>
                <span class="chioceBody">
                  <span
                    class="chioceBodyOpt"
                    v-for="(valueItem, valueIndex) in item.value"
                    :class="{ active: item.active == valueIndex }"
                    :key="valueIndex"
                    @click="
                      getColor(
                        valueIndex,
                        item,
                        index,
                        scope.row.specification_checked,
                        scope.$index
                      )
                    "
                  >
                    {{ valueItem.name }}
                  </span>
                </span>
              </div>
            </div>
            <div slot="reference" class="goodsDetail">
              <div class="detail">
                <div class="detailCon">
                  <p
                    v-for="(item, index) in scope.row.specification_checked"
                    :key="index"
                    :title="item.key + ':' + item.value"
                  >
                    {{ item.key }} : {{ item.value }}
                  </p>
                  <!-- {{ scope.row.specification }} -->
                </div>
              </div>
              <img src="../../../../assets//newImg/order/modify.svg" alt="" />
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        property="price"
        :label="$fanyi('单价')"
        width="130"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            >{{
              $fun.EURNumSegmentation(
                $fun.ceil(scope.row.price * $store.state.exchangeRate)
              )
            }}€({{ $fun.RMBNumSegmentation(scope.row.price) }}￥)</span
          >
        </template>
      </el-table-column>
      <el-table-column
        property="status"
        :label="$fanyi('商品状态')"
        width="145"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.status == 1 ? $fanyi("正常") : $fanyi("已下架") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column property="num" width="158" :label="$fanyi('数量')">
        <template slot-scope="scopeer">
          <div>
            <el-input-number
              size="small"
              v-model="scopeer.row.num"
              :min="1"
              @change="saveGoodsNum(scopeer.row)"
            ></el-input-number>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="name" :label="$fanyi('附加服务')" width="220">
        <template slot-scope="scope">
          <el-popover placement="bottom" trigger="click" ref="optionListpro">
            <div class="optionsGroup">
              <el-table
                :data="optionList"
                cell-class-name="cellClassName"
                header-row-class-name="cellHeadClassName"
                :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column property="date" width="40" label="">
                  <template slot-scope="scopeer">
                    <div>
                      <input
                        class="chiose"
                        type="checkBox"
                        :checked="scopeer.row.checked"
                        @click="
                          scopeer.row.checked = !scopeer.row.checked;
                          setoneGoodsOptionTotalPrice();
                        "
                      />
                    </div> </template
                ></el-table-column>
                <el-table-column
                  property="name_translate"
                  width="260"
                  :label="$fanyi('附加服务选择')"
                ></el-table-column>
                <el-table-column
                  property="num"
                  width="158"
                  :label="$fanyi('数量')"
                >
                  <template slot-scope="scopeer">
                    <div>
                      <el-input-number
                        size="small"
                        v-model="scopeer.row.num"
                        :min="1"
                        @change="setoneGoodsOptionTotalPrice()"
                      ></el-input-number>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  property="price"
                  width="128"
                  :label="$fanyi('单价')"
                >
                </el-table-column>
                <el-table-column width="158" :label="$fanyi('预计费用')">
                  <template slot-scope="scope">
                    {{
                      $fun.RMBNumSegmentation(scope.row.price * scope.row.num)
                    }}RMB
                  </template>
                </el-table-column>
                <el-table-column width="158">
                  <template slot-scope="scope">
                    <el-input
                      size="small"
                      v-if="scope.row.checked && scope.row.name == 'SKU'"
                      v-model="scope.row.fba"
                      type="textarea"
                      placeholder="FBA（FNSKU）"
                    />
                  </template>
                </el-table-column>
              </el-table>
              <div class="foot">
                <p>
                  {{ $fanyi("合计约为（元）") }}：<font>{{
                    oneGoodsOptionTotalPrice
                  }}</font>
                </p>
                <p>
                  <button class="react" @click="optionReact(scope.$index)">
                    {{ $fanyi("重置") }}
                  </button>
                  <button class="save" @click="saveOrderOption(scope.row)">
                    {{ $fanyi("保存") }}
                  </button>
                </p>
              </div>
            </div>
            <div
              slot="reference"
              @click="
                setOrderOption(scope.row.option);
                setoneGoodsOptionTotalPrice();
              "
              class="optionsBox"
            >
              <p
                class="options"
                v-for="(optItem, optIndex) in scope.row.option"
                :key="optIndex"
                v-show="optItem.checked"
              >
                {{ optItem.tag }}{{ optItem.name_translate }} *
                {{ optItem.num }}
              </p>
              <p class="ploceholdX" v-show="haveOption(scope.row.option)">
                {{ $fanyi("点击选择附加服务") }}
              </p>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column property="" :label="$fanyi('备注')" width="180px">
        <template slot-scope="scope">
          <el-input
            type="textarea"
            :rows="5"
            :ref="'reIn' + scope.$index"
            :placeholder="$fanyi('请输入备注')"
            v-model="scope.row.note"
            @change="changeGoodsNote(scope.row.id, scope.row.note)"
            v-focus
          >
          </el-input>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="allpag">
      <a href="javascript:;" @click="
        form.page = 1;
      getData();
                ">{{ $fanyi("回到首页") }}</a>
      <el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";
import addDia from "./commpones/addDia.vue";
export default {
  data() {
    return {
      checked: false, //全选
      form: {
        pageSize: 9999,
      },
      input1: "", //搜索内容
      tableData: [], //表格数据
      optionList: [], //附加服务列表数据
      multipleSelection: [], //附加服务已选中的数据
      actIds: [], //选中的id
      actCartIds: [], //选中的购物车id
      oneGoodsOptionTotalPrice: 0,
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("删除"), this.$fanyi("取消")],
      },
      addDiaStuts: false,
      total: 0,
    };
  },
  components: { Dialog, addDia },
  computed: {},
  created() {
    this.form.page = 1;
    this.getData();
    this.getOptionList();
  },
  methods: {
    // placehold显示判断
    haveOption(optionList) {
      let show = true;
      optionList.forEach((item) => {
        if (item.checked) {
          show = false;
        }
      });
      return show;
    },
    // 保存商品数量
    saveGoodsNum(item) {
      this.$api
        .favoriteInventorySpecificationNum({
          id: item.id,
          num: item.num,
        })
        .then((res) => {
          //console.log("favoriteInventorySpecificationNum", res);
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          //接下来的操作;
        });
    },
    // 计算单条商品附加服务总费用
    setoneGoodsOptionTotalPrice() {
      this.oneGoodsOptionTotalPrice = 0;
      this.optionList.forEach((optionsItem) => {
        if (optionsItem.checked)
          this.oneGoodsOptionTotalPrice += optionsItem.num * optionsItem.price;
      });
      // 保留两位小数并添加千位符
      this.oneGoodsOptionTotalPrice = this.$fun.RMBNumSegmentation(
        this.$fun.ceil(this.oneGoodsOptionTotalPrice)
      );
      this.$forceUpdate();
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.form.page = 1;
      this.getData(this.form);
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },
    //   获取数据
    getData() {
      this.$api
        .favoriteInventoryList(this.form)
        .then((res) => {
          this.tableData = res.data.data;
          this.dataProcessing();
          this.total = res.data.total;
        })
        .catch((err) => {});
    },
    // 数据处理
    dataProcessing() {
      this.tableData.forEach((item, index) => {
        item.checked = false;

        item.specification = ((data) => {
          let skuProps = [];
          // data.specification = JSON.parse(data.specification);
          Object.keys(data.specification).forEach((key) => {
            skuProps.push({
              key: key.substr(1, key.length - 2),
              value: data.specification[key],
              active: 0,
            });
          });
          //console.log("safd", skuProps);
          return skuProps;
        })(item);
        item.specificationChinese = ((data) => {
          let skuProps = [];
          // data.specification = JSON.parse(data.specification);
          Object.keys(data.specificationChinese).forEach((key) => {
            skuProps.push({
              key: key.substr(1, key.length - 2),
              value: data.specificationChinese[key],
              active: 0,
            });
          });
          //console.log("safd", skuProps);
          return skuProps;
        })(item);
        // //console.log(item.specification);
        // 如果接口有返回已选中的选项，则选中返回的选择，否则选中第一项,并把第一项的选项填充到已选中选项数组里
        // item.specification.forEach((ctem, cndex) => {
        //   if (!!item.specification_checked[cndex]) {
        //     let act = 0;
        //     ((str) => {
        //       ctem.value.forEach((ctemValue, ctemIndex) => {
        //         if (ctemValue.name == str) {
        //           act = ctemIndex;
        //         }
        //       });
        //     })(item.specification_checked[cndex]);
        //     ctem.active = act;
        //   } else {
        //     item.specification_checked[cndex] = ctem.value[ctem.active].name;
        //   }
        // });
        item.specification_checked.forEach((ctem, cndex) => {
          //console.log(ctem, item.specification[cndex].value);
          item.specification[cndex].value.forEach(
            (specificationItem, specificationIndex) => {
              //console.log(ctem.value, specificationItem.name);
              if (ctem.value == specificationItem.name) {
                //console.log("匹配");
                item.specification[cndex].active = specificationIndex;
              }
            }
          );
        });
        this.getPrice(item.specification_checked, index);
      });
    },
    // 商品规格选择 传入参数：选择的商品选项的下标，单个商品选项组，单个商品选项组的下标，商品所有选项， 单条商品的下标，
    getColor(
      active,
      detail,
      detailIndex,
      goodsSpecification_checked,
      goodsIndex
    ) {
      let oldActive = JSON.parse(JSON.stringify(active));
      detail.active = active;
      // return //console.log(detail);
      goodsSpecification_checked[detailIndex].value = detail.value[active].name;
      // 将传递的商品选中规格匹配成中文
      let checked_Chinese = [];
      let specificationChinese = this.tableData[goodsIndex]
        .specificationChinese;
      this.tableData[goodsIndex].specification.forEach(
        (checkedItem, checkedIndex) => {
          checked_Chinese.push({
            key: specificationChinese[checkedIndex].key,
            value:
              specificationChinese[checkedIndex].value[checkedItem.active].name,
          });
        }
      );

      // return //console.log(checked_Chinese);
      this.$api
        .favoriteInventorySpecificationChecked({
          id: this.tableData[goodsIndex].id,
          specification_checked: JSON.stringify(checked_Chinese),
        })
        .then((res) => {
          //console.log("favoriteInventorySpecificationChecked", res);
          if (res.code != 0) {
            active = oldActive;
            goodsSpecification_checked[detailIndex] = detail.value[active].name;
            return;
          }

          this.getPrice(goodsSpecification_checked, goodsIndex);
          this.$forceUpdate();
        });
    },
    // 根据选择条件匹配价格
    getPrice(str, tabDaIndex) {
      // 将str的{key,value}格式转化为字符串㊖㊎格式
      str = (() => {
        let arr = [];
        str.forEach((item) => {
          arr.push(item.value);
        });
        return arr.join("㊖㊎");
      })();
      //console.log(str);
      let data = {};
      // 循环遍历对象寻找
      str = '"' + str + '"';
      Object.keys(this.tableData[tabDaIndex].goods_inventory).forEach((key) => {
        if (key == str) {
          data = this.tableData[tabDaIndex].goods_inventory[key];
          return false;
        }
      });
      this.tableData[tabDaIndex].price = data.price;
      this.$forceUpdate();
    },
    // 保存商品备注
    changeGoodsNote(id, note) {
      this.$api
        .favoriteInventoryNote({
          id: id,
          note: note,
        })
        .then((res) => {
          if (res.code != 0) return this.message.error(res.msg);
        });
    },
    // 单选
    oddChoice(i) {
      // let flag = this.tableData.every((item) => item.checked == true);

      this.isCheckedAll();
      this.$forceUpdate();
      //   flag ? (this.tableData[i].checked = true) : (this.tableData[i].checked = false);
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.actIds = [];
        this.tableData.forEach((item, index) => {
          this.actIds.push(item.id);
          item.checked = true;
        });
      } else {
        this.actIds = [];
        this.tableData.forEach((item, index) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = true;
      this.actIds = [];
      this.tableData.forEach((item) => {
        if (item.checked == false) {
          flag = false;
        } else {
          this.actIds.push(item.id);
        }
      });
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$forceUpdate();
      //console.log(this.checked, flag);
    },
    // 删除商品
    delGoods() {
      if (this.actIds.length == 0) {
        return this.$message(this.$fanyi("请至少选择一个商品"));
      }
      this.$refs.dialog.open(
        this.$fanyi("是否删除选中的商品"),
        () => {
          this.$api
            .favoriteInventoryDelete({
              ids: this.actIds.join(","),
            })
            .then((res) => {
              //console.log("favoriteInventoryDelete", res);
              if (res.code != 0) return;
              for (let i = 0; i < this.tableData.length; i++) {
                if (this.actIds.indexOf(this.tableData[i].id) != -1) {
                  this.tableData.splice(i, 1);
                  i--;
                }
              }
            });
        },
        () => {}
      );
    },
    // 获取附加服务列表
    getOptionList() {
      this.$api.optionList().then((res) => {
        this.optionList = res.data;
      });
    },
    // 设置附加服务-重置

    optionReact(i) {
      for (var i in this.optionList) {
        this.optionList[i].checked = false;
        this.optionList[i].num = 1;
      }
      let checkOptionBox = document.getElementsByClassName("checkOptionBox");
      for (let i = 0; i < checkOptionBox.length; i++) {
        checkOptionBox[i].checked = false;
      }
    },
    // 设置附加服务-保存选择的附加服务
    saveOrderOption(item) {
      item.option = JSON.parse(JSON.stringify(this.optionList));
      //console.log(item.option);
      this.$api
        .favoriteInventoryOption({
          id: item.id,
          option: JSON.stringify(item.option),
        })
        .then((res) => {
          //console.log("favoriteInventoryOption", res);
          if (res.code != 0)
            return this.$message.error(this.$fanyi("操作失败"));
          this.$refs.optionListpro.doClose();
        });
      this.$forceUpdate();
    },
    // 设置附加服务-打开弹窗并初始化数据和选中已选附加服务
    setOrderOption(item) {
      this.optionList = JSON.parse(JSON.stringify(item));
      this.$forceUpdate();
      // this.showOptionList = true;
    },
    //提出订单
    jieSuan() {
      if (this.actIds.length == 0) {
        return this.$message(this.$fanyi("请至少选择一个商品"));
      }
      let id = this.actIds.join(",");
      this.$fun.routerToPage(
        "/OrderDetails?id=" + id + "&type=temporary&iscomm=true",
        true
      );
    },
    // 点击备注图片时将焦点移到备注输入框
    getFocuects(index) {
      //console.log(this.$refs["reIn" + index]);
      this.$refs["reIn" + index].focus();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../css/mixin.scss";

#commodity {
  background-color: white;
  padding: 0 20px 20px;

  nav {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;
    }

    .navLeft {
      .checkBoxZu {
        display: flex;
        align-items: center;

        .che {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 20px;

          input {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        button {
          height: 19px;
          font-size: 14px;
          color: #1e2997;
          line-height: 19px;
          background-color: transparent;
          margin-right: 40px;
        }
      }

      .inputBox {
        width: 400px;
        display: flex;
        align-items: center;

        .imgBox {
          width: 40px;
          height: 40px;
          border: $homePageSubjectColor solid 1px;
          border-right: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px 0 0 20px;

          img {
            width: 17px;
            height: 24px;
          }
        }

        /deep/.el-input {
          border-radius: 0;

          .el-input__inner {
            border-left: none;
            border-right: none;
            border-color: $homePageSubjectColor;
            border-radius: 0;
          }
        }

        /deep/.el-button {
          width: 80px;
          height: 40px;
          background: #1e2997;
          border-radius: 4px;
          margin-left: -17px;
          position: relative;
          z-index: 1;
        }
      }
    }

    .navRight {
      .exportBox {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }

        span {
          line-height: 19px;
          font-size: 14px;
          margin-right: 40px;
        }
      }

      .el-button {
        height: 40px;
        border-radius: 4px;
      }

      .ProponerUnPedido {
        background-color: #ffa724;
        border: none;
        color: white;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  /deep/.el-table {
    .chiose {
      margin: 0 auto;
    }

    .goodsUrl {
      text-align: left;
      height: 117px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      a {
        &:hover {
          color: $homePageSubjectColor !important;
          text-decoration: underline !important;
        }
      }
    }

    .optionsBox {
      width: 200px;
      height: 117px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      padding: 8px 10px;
      overflow-y: auto;
      cursor: pointer;

      .options {
        text-align: left;
        font-size: 12px;
        color: #000000;
        line-height: 16px;
      }

      .ploceholdX {
        color: #c0c4cc;
        text-align: left;
      }
    }

    .noteBox {
      img {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }

    .goodsDetail {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .detail {
        flex: 1;

        .detailCon {
          width: 100%;
          overflow-y: auto;
          max-height: 100px;

          p {
            width: 100px;

            text-align: left;
          }
        }
      }

      img {
        width: 16px;
        height: 16px;
        margin-left: 20px;
      }

      .detailChoice {
        position: absolute;
        top: 0px;
        width: 420px;
        padding: 20px;
        background: red;
        border: solid;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

/deep/.el-popper {
  padding: 20px !important;
}

.chioceDetail {
  max-height: 500px;
  overflow-y: auto;

  > .dia {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .chioceTitle {
      display: block;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #000000;
      line-height: 18px;
    }

    .chioceBody {
      display: flex;
      flex-wrap: wrap;

      .chioceBodyOpt {
        margin-right: 10px;
        border-radius: 2px;
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 16px;

        border: 1px solid #cccccc;
        padding: 7px 8px;
        cursor: pointer;

        &.active {
          color: #ffa724;
          border: 1px solid #ffa724;
        }
      }
    }
  }
}

/deep/.cellClassName {
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  border: none;
  padding: 5px;
}

/deep/.cellHeadClassName {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  line-height: 16px;
}

/deep/.el-checkbox__input {
  height: 16px !important;
}

/deep/.el-table td.el-table__cell {
  border: none !important;
}

/deep/.numInputBox {
  padding: 0;

  .numInput {
    .el-input__inner {
      height: 22px;
      padding: 0;
      text-align: center;
    }
  }
}

.allpag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;

  > a {
    display: inline-block;
    border: 1px solid #dcdfe6;
    height: 28px;
    background-color: white;
    text-align: center;
    padding: 0 10px;
    font-size: 13px;
    line-height: 28px;
    border-radius: 2px;
  }

  /* 分页被选中的页码样式 */
  /deep/.el-pager li {
    margin: 0 3px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

    &:hover {
      color: $homePageSubjectColor;
    }

    &.active {
      background-color: $homePageSubjectColor;
      color: white;
    }
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.foot {
  text-align: right;

  > p {
    font-size: 12px;

    font-weight: bold;
    color: #000000;
    line-height: 16px;

    font {
      color: #ffa724;
    }

    &:first-child {
      margin-bottom: 12px;
    }

    button {
      font-size: 14px;

      color: #000000;
      line-height: 20px;
      background-color: transparent;
      border: solid 1px #47bd9b;
      border-radius: 5px;
      padding: 5px 12px;
      outline: none;
      color: #47bd9b;

      &.react {
        margin-right: 20px;
      }

      &.save {
        background-color: #47bd9b;
        color: $homePageSubjectColor;
        color: white;
      }
    }
  }
}
</style>
